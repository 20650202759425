<template>
  <div class="category-path">
    <span v-for="(parent, index) in parents" :key="parent.id">
      <span v-if="index > 0"> / </span>
      <span class="parent-label">{{ parent.name }}</span>
    </span>
  </div>
</template>

<script>
import { find } from 'lodash-es'

export default {
  props: {
    parentId: String
  },
  computed: {
    parents () {
      return this.getRecursiveParents(this.parentId)
    }
  },
  methods: {
    getParent (parentId) {
      return find(this.$store.state.productCategories.collection, { id: parentId })
    },
    getRecursiveParents (parentId) {
      const parents = []

      let parent = this.getParent(parentId)

      if (parent) {
        parents.push(parent)

        while (parent && parent.parentId) {
          parent = this.getParent(parent.parentId)

          if (parent) {
            parents.push(parent)
          }
        }
      }

      return parents.reverse()
    }
  }
}
</script>
